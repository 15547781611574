SetDesign(($) => {
    function sideOfDots(classNames, numberOfDots) {
        const dotsHtml = [...Array(numberOfDots)].map(() => '<div class="fborder__dot"></div>').join('');
        return `<div class='${classNames}'>${dotsHtml}</div>`;
    }

    $('.fborder').each((_, elem) => {
        const $container = $(elem);
        const $border = $('<div class="fborder__inner"></div>');
        $border.append($(sideOfDots('fborder__top', 60)));
        $border.append($(sideOfDots('fborder__left', 40)));
        $border.append($(sideOfDots('fborder__right', 40)));
        $border.append($(sideOfDots('fborder__bottom', 60)));
        $container.prepend($border);
        let odds = 50;
        $border.find('.fborder__dot:even').each((index, el) => {
            const time = Math.random() * 1000;
            const rand = (Math.random() * 100) - odds;
            const className = (Math.random() * 100) - 50 > 0 ? 'flashing' : 'inverse-flashing';
            if (rand >= 0) {
                odds = 60;
                setTimeout(() => { $(el).addClass(className); }, time);
            } else {
                odds -= 10;
            }
        });
    });
});
