import { addFilter } from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    ...{
        navText: [
            '<svg><use xlink:href="#icon-left"></use></svg>',
            '<svg><use xlink:href="#icon-right"></use></svg>',
        ],
    },
}));
