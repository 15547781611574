import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.post-list').each((index, container) => {
        const $container = $(container);
        const $posts = $container.find('.post-excerpt').addClass('post-excerpt--hide');
        const $more = $container.find('.post-list__more');
        const count = $container.data('count') || 10;
        $posts.addClass('post-excerpt--hide');

        function showMore(e) {
            if (e) {
                e.preventDefault();
            }
            const $hidden = $posts.filter('.post-excerpt--hide');
            if ($hidden.length < count) {
                $more.remove();
            }
            $hidden.slice(0, count).removeClass('post-excerpt--hide');
            doAction('refreshLazyLoad', $container);
        }

        $container.removeClass('post-list--hide');
        $more.find('.button').on('click', showMore);
        showMore();
    });
});
