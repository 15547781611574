import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    if (!$('body').hasClass('has-sidebar')) {
        return;
    }
    const $copy = $('.sidebar').clone();
    const $aside = $('<aside>');
    const $after = $('.single-column').clone();
    $aside.append($copy);
    $('.main').append($aside);
    $('.wrap').append($after);
});
