import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

const enable = window.sit.calendarTest.enable || false;
const force = window.sit.calendarTest.force || false;
const override = window.sit.calendarTest.alternateLink || false;

addAction(INIT, () => {
    if (!enable || !override || (!force && !Math.round(Math.random()))) {
        return;
    }
    const $links = $('a[href*="/tickets"]');
    $links.attr('href', override).attr('target', '_blank');
});
